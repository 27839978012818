export default {
  danger: {
    color: "white",
    backgroundColor: "#b22a2a",
  },
  warning: {
    color: "white",
    backgroundColor: "#e0840b",
  },
  success: {
    color: "white",
    backgroundColor: "#47a508",
  },
};
