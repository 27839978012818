import types from "@/utils/mutation-types";
import alertTypes from "@/utils/alert-types";
import axios from "@/utils/axios";
import errorHandler from "@/utils/error";

const manualStore = {
  state: () => ({
    manuals: [],
  }),
  mutations: {
    [types.SET_MANUALS](state, manuals) {
      state.manuals = manuals;
    },
    [types.ADD_MANUAL_TO_LIST](state, manual) {
      state.manuals.unshift(manual);
    },
    [types.REMOVE_MANUAL_FROM_LIST](state, manualId) {
      state.manuals = state.manuals.filter((c) => c._id !== manualId);
    },
  },
  actions: {
    async getManuals({ commit }, data) {
      try {
        let uri = `${process.env.VUE_APP_API_HOST}/api/v1/manuals`;

        const { folderid, brand } = data;

        if (folderid) {
          uri = `${uri}?folder=${folderid}`;
        } else if (brand) uri = `${uri}?brand=${brand}`;

        const res = await axios.get(uri);

        if (res.status === 200) {
          commit(types.SET_MANUALS, res.data.manuals);
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async deleteManual({ commit, getters, dispatch }, id) {
      try {
        const res = await axios.delete(
          `${process.env.VUE_APP_API_HOST}/api/v1/manuals/${id}`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );
        if (res.status === 200) {
          commit(types.REMOVE_MANUAL_FROM_LIST, res.data.manual._id);
          dispatch("addAlert", {
            ...alertTypes.warning,
            alert: "manual deleted",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async addManual({ commit, getters, dispatch }, data) {
      try {
        const { newManual, files, brandid, folderid } = data;

        // Upload file
        const fileFd = new FormData();
        fileFd.append("file", files.file);
        newManual.file = await dispatch("singleUpload", fileFd);

        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/manuals`,
          { ...newManual, folder: folderid, brand: brandid },
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 201) {
          commit(types.ADD_MANUAL_TO_LIST, res.data.manual);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "manual added",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
  },
  getters: {
    manuals(state) {
      return state.manuals;
    },
  },
};

export default manualStore;
