import types from "@/utils/mutation-types";
import { v4 as uuid4 } from "uuid";
const alertStore = {
  state: () => ({
    alerts: [],
  }),
  mutations: {
    [types.APPEND_ALERTS](state, alert) {
      state.alerts.push(alert);
    },
    [types.REMOVE_ALERT](state, id) {
      state.alerts = state.alerts.filter((alert) => alert.id !== id);
    },
  },
  actions: {
    addAlert({ commit, state }, alert) {
      alert.id = uuid4();

      const hasLoginAlert = state.alerts.findIndex((alert) => {
        if (alert.alert === "please login") {
          return true;
        } else {
          return false;
        }
      });

      if (hasLoginAlert == -1) {
        commit(types.APPEND_ALERTS, alert);
        setTimeout(() => {
          commit(types.REMOVE_ALERT, alert.id);
        }, 4000);
      }
    },
  },
  getters: {
    alerts(state) {
      return state.alerts;
    },
  },
};

export default alertStore;
