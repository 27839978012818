import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/components/pages/Login"),

    meta: {
      layout: "LoginLayout",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/components/pages/ForgotPassword"),

    meta: {
      layout: "LoginLayout",
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/components/pages/ResetPassword"),

    meta: {
      layout: "LoginLayout",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/components/pages/Dashboard"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/tickets",
    name: "Tickets",
    component: () => import("@/components/pages/Tickets"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("@/components/pages/Users"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/masterkey",
    name: "Master Key",
    component: () => import("@/components/pages/MasterKey"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/users/:id",
    name: "Single User",
    component: () => import("@/components/pages/SingleUser"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/categories",
    name: "Categories",
    component: () => import("@/components/pages/Categories"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/inquiries",
    name: "Inquiries",
    component: () => import("@/components/pages/Inquiries"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/inquiries/:id",
    name: "Inquiry",
    component: () => import("@/components/pages/SingleInquiry"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/customers",
    name: "Customers",
    component: () => import("@/components/pages/Customers"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/customer-companies",
    name: "Customer Companies",
    component: () => import("@/components/pages/CustomerCompanies"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/vendors",
    name: "Vendors",
    component: () => import("@/components/pages/Vendors"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/document-brands",
    name: "Doc. Brands",
    component: () => import("@/components/pages/DocumentBrands"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/catalogues",
    component: () => import("@/components/pages/Catalogues/index"),
    meta: {
      layout: "MainLayout",
    },
    children: [
      {
        path: "",
        name: "Catalogues & Brochures",
        component: () => import("@/components/pages/Catalogues/Catalogues"),
      },
      {
        name: "Catalogues Folder",
        path: ":brandid",
        component: () =>
          import("@/components/pages/Catalogues/SingleBrandCatalogues"),
      },
    ],
  },
  {
    path: "/manuals",
    component: () => import("@/components/pages/Manuals/index"),
    meta: {
      layout: "MainLayout",
    },
    children: [
      {
        path: "",
        name: "Manuals",
        component: () => import("@/components/pages/Manuals/Manuals"),
      },
      {
        path: ":brandid",
        name: "Brand Manual Folder",
        component: () =>
          import("@/components/pages/Manuals/BrandManualFolders"),
      },
    ],
  },
  {
    path: "/trending-slides",
    name: "Trending Slides",
    component: () => import("@/components/pages/TrendingSlides"),

    meta: {
      layout: "MainLayout",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    to;
    from;
    savedPosition;
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (
    to.name &&
    !to.name.match(/(Login|ForgotPassword|ResetPassword)/gi) &&
    !store.getters.isAuthenticated
  ) {
    next({ name: "Login" });
  } else if (
    to.name &&
    to.name.match(/(Login|ForgotPassword|ResetPassword)/gi) &&
    store.getters.isAuthenticated
  ) {
    next({ name: "Dashboard" });
  } else {
    next();
  }
});

export default router;
