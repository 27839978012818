import types from "@/utils/mutation-types";
import alertTypes from "@/utils/alert-types";
import axios from "@/utils/axios";
import errorHandler from "@/utils/error";
const usersStore = {
  state: () => ({
    users: [],
  }),
  mutations: {
    [types.SET_USERS](state, users) {
      state.users = users;
    },
    [types.ADD_USER_TO_LIST](state, user) {
      state.users.push(user);
    },
    [types.REMOVE_USER_FROM_LIST](state, user) {
      state.users = state.users.filter((u) => u._id !== user._id);
    },
    [types.UPDATE_USER_IN_LIST](state, user) {
      state.users = state.users.map((u) => {
        if (u._id !== user._id) return u;
        else return user;
      });
    },
  },
  actions: {
    async getUsers({ commit, getters }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_HOST}/api/v1/users`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status == 200) {
          commit(types.SET_USERS, res.data.users);
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async addUser({ commit, getters, dispatch }, user) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/users`,
          user,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status == 201) {
          commit(types.ADD_USER_TO_LIST, res.data.user);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "user added",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async editUser({ commit, getters, dispatch }, user) {
      try {
        const res = await axios.patch(
          `${process.env.VUE_APP_API_HOST}/api/v1/users/${user._id}`,
          user,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status == 200) {
          commit(types.UPDATE_USER_IN_LIST, res.data.user);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "user updated",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },

    async deleteUser({ commit, getters, dispatch }, user) {
      try {
        const res = await axios.delete(
          `${process.env.VUE_APP_API_HOST}/api/v1/users/${user._id}`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status == 200) {
          commit(types.REMOVE_USER_FROM_LIST, res.data.user);
          dispatch("addAlert", {
            ...alertTypes.warning,
            alert: "user deleted",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
  },
  getters: {
    users(state) {
      return state.users;
    },
  },
};

export default usersStore;
