import types from '@/utils/mutation-types';
import axios from '@/utils/axios';
import errorHandler from '@/utils/error';
import alertTypes from '@/utils/alert-types';
import { handleError } from '@vue/runtime-dom';

const customersStore = {
  state: () => ({
    customers: [],
    allCustomers: [],
    customersSearchValue: '',
    page: 1,
    totalPages: 0,
    totalCustomers: 0,
  }),
  mutations: {
    [types.SET_CUSTOMERS](state, customers) {
      state.customers = customers;
    },
    [types.SET_ALL_CUSTOMERS](state, customers) {
      state.allCustomers = customers;
    },
    [types.ADD_CUSTOMER_TO_LIST](state, customer) {
      state.customers.push(customer);
    },
    [types.UPDATE_CUSTOMER_IN_LIST](state, customer) {
      state.customers = state.customers.map((customerObj) => {
        if (customerObj._id === customer._id) return customer;
        else return customerObj;
      });
    },
    [types.REMOVE_CUSTOMER_FROM_LIST](state, id) {
      state.customers = state.customers.filter(
        (customer) => customer._id != id
      );
    },
    [types.SET_CUSTOMERS_SEARCH](state, data) {
      state.customersSearchValue = data;
    },
    [types.SET_CUSTOMERS_PAGE](state, data) {
      state.page = data;
    },
    [types.SET_CUSTOMERS_TOTAL_PAGES](state, data) {
      state.totalPages = data;
    },
    [types.SET_TOTAL_CUSTOMERS](state, data) {
      state.totalCustomers = data;
    },
  },
  actions: {
    async getCustomers({ commit, getters }, options = {}) {
      try {
        let uri = `${process.env.VUE_APP_API_HOST}/api/v1/customers?page=${getters.customersPage}`;

        if (getters.customersSearchValue) {
          uri = `${uri}&search=${getters.customersSearchValue}`;
        }

        if (options.all === true) {
          uri = `${uri}&getAll=true`;
        }

        const res = await axios.get(uri, {
          headers: {
            authorization: getters.bearerToken,
          },
        });

        if (res.status === 200) {
          if (!options.all) {
            commit(types.SET_CUSTOMERS, res.data.customers);
            commit(types.SET_CUSTOMERS_TOTAL_PAGES, res.data.totalPages);
            commit(types.SET_TOTAL_CUSTOMERS, res.data.totalCustomers);
            commit(types.SET_CUSTOMERS_PAGE, res.data.page);
          } else {
            commit(types.SET_ALL_CUSTOMERS, res.data.customers);
          }
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },

    async addCustomer({ commit, getters, dispatch }, customer) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/customers`,
          customer,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status == 200) {
          commit(types.ADD_CUSTOMER_TO_LIST, res.data.customer);
          dispatch('addAlert', {
            ...alertTypes.success,
            alert: 'customer added',
          });
        }
      } catch (e) {
        console.log(e);
        errorHandler(e);
      }
    },

    async editCustomer({ getters, commit, dispatch }, customer) {
      try {
        const res = await axios.patch(
          `${process.env.VUE_APP_API_HOST}/api/v1/customers/${customer._id}`,
          customer,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status == 200) {
          commit(types.UPDATE_CUSTOMER_IN_LIST, res.data.customer);
          dispatch('addAlert', {
            ...alertTypes.success,
            alert: 'customer saved',
          });
        }
      } catch (e) {
        console.log(e);
        errorHandler(e);
      }
    },

    async sendCustomerPasswordReset({ getters, commit, dispatch }, customer) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/customers/send-password-reset`,
          { user: customer },
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status == 200) {
          commit(types.UPDATE_CUSTOMER_IN_LIST, res.data.customer);
          dispatch('addAlert', {
            ...alertTypes.success,
            alert: 'password reset mail sent.',
          });
        }
      } catch (e) {
        console.log(e);
        errorHandler(e);
      }
    },

    async deleteCustomer({ getters, commit, dispatch }, id) {
      try {
        const res = await axios.delete(
          `${process.env.VUE_APP_API_HOST}/api/v1/customers/${id}`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status == 200) {
          commit(types.REMOVE_CUSTOMER_FROM_LIST, res.data.customerId);
          dispatch('addAlert', {
            ...alertTypes.warning,
            alert: 'customer deleted!',
          });
        }
      } catch (e) {
        console.log(e);
        handleError(e);
      }
    },
  },
  getters: {
    customers(state) {
      return state.customers;
    },
    allCustomers(state) {
      return state.allCustomers;
    },
    customersSearchValue(state) {
      return state.customersSearchValue;
    },
    totalCustomers(state) {
      return state.totalCustomers;
    },
    customersPage(state) {
      return state.page;
    },
    customersTotalPages(state) {
      return state.totalPages;
    },
  },
};

export default customersStore;
