import types from "@/utils/mutation-types";
import axios from "@/utils/axios";
import errorHandler from "@/utils/error";

const branchesStore = {
  state: () => ({
    branches: [],
  }),
  mutations: {
    [types.SET_BRANCHES](state, branches) {
      state.branches = branches;
    },
  },
  actions: {
    async getBranches({ commit, getters }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_HOST}/api/v1/branches`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.SET_BRANCHES, res.data.branches);
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },
  },
  getters: {
    branches(state) {
      return state.branches;
    },
  },
};

export default branchesStore;
