import types from "@/utils/mutation-types";
import axios from "@/utils/axios";
import errorHandler from "@/utils/error";
import alertTypes from "@/utils/alert-types";
import { handleError } from "@vue/runtime-dom";

const customerCompanyStore = {
  state: () => ({
    customerCompanies: [],
  }),
  mutations: {
    [types.SET_CUSTOMER_COMPANIES](state, customerCompanies) {
      state.customerCompanies = customerCompanies;
    },
    [types.ADD_CUSTOMER_COMPANY_TO_LIST](state, customerCompany) {
      state.customerCompanies.push(customerCompany);
    },
    [types.UPDATE_CUSTOMER_COMPANY_IN_LIST](state, customerCompany) {
      state.customerCompanies = state.customerCompanies.map(
        (customerCompanyObj) => {
          if (customerCompanyObj._id === customerCompany._id)
            return customerCompany;
          else return customerCompanyObj;
        }
      );
    },
    [types.REMOVE_CUSTOMER_COMPANY_FROM_LIST](state, id) {
      state.customerCompanies = state.customerCompanies.filter(
        (customerCompany) => customerCompany._id != id
      );
    },
  },
  actions: {
    async getCustomerCompanies({ commit, getters }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_HOST}/api/v1/customer-companies`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.SET_CUSTOMER_COMPANIES, res.data.customerCompanies);
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },

    async addCustomerCompany({ commit, getters, dispatch }, customerCompany) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/customer-companies`,
          customerCompany,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status == 200) {
          commit(types.ADD_CUSTOMER_COMPANY_TO_LIST, res.data.customerCompany);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "customer company added",
          });
        }
      } catch (e) {
        console.log(e);
        errorHandler(e);
      }
    },

    async editCustomerCompany({ getters, commit, dispatch }, customerCompany) {
      try {
        const res = await axios.patch(
          `${process.env.VUE_APP_API_HOST}/api/v1/customer-companies/${customerCompany._id}`,
          customerCompany,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status == 200) {
          commit(
            types.UPDATE_CUSTOMER_COMPANY_IN_LIST,
            res.data.customerCompany
          );
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "customer company saved",
          });
        }
      } catch (e) {
        console.log(e);
        errorHandler(e);
      }
    },

    async deleteCustomerCompany({ getters, commit, dispatch }, id) {
      try {
        const res = await axios.delete(
          `${process.env.VUE_APP_API_HOST}/api/v1/customer-companies/${id}`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status == 200) {
          commit(
            types.REMOVE_CUSTOMER_COMPANY_FROM_LIST,
            res.data.customerCompanyId
          );
          dispatch("addAlert", {
            ...alertTypes.warning,
            alert: "customer company deleted!",
          });
        }
      } catch (e) {
        console.log(e);
        handleError(e);
      }
    },
  },
  getters: {
    customerCompanies(state) {
      return state.customerCompanies;
    },
  },
};

export default customerCompanyStore;
