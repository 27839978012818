<template>
  <div class="flex layout-container">
    <TopBar :leftValue="topBarLeftValue" :width="contentAreaWidth"> </TopBar>
    <div
      :style="{ maxWidth: sidebarMaxWidth }"
      ref="sidebar"
      style="background-color: #212121"
      :class="{ 'sidebar-collapsed': sidebarCollapsed }"
      class="sidebar hide-scroll-bar flex flex-col shadow-lg z-50 h-full fixed"
    >
      <div
        class="
          flex
          bg-white
          items-center
          h-14
          p-4
          border border-l-0 border-t-0 border-b-0
        "
        :class="{ 'px-6': !sidebarCollapsed, 'p-1': sidebarCollapsed }"
      >
        <img
          src="@/assets/logo.png"
          style="height: 100%"
          :class="{ 'mr-2 hidden': !sidebarCollapsed }"
          alt="Logo Globe"
        />
        <img
          src="@/assets/logoheader.png"
          :class="{ hidden: sidebarCollapsed }"
          style="width: 80%"
        />
      </div>
      <div class="flex w-full h-full text-white text-sm">
        <ul class="w-full h-full flex flex-col">
          <li class="w-full mt-1 hover:bg-gray-600">
            <router-link
              class="px-3 py-2 flex items-center justify-left"
              to="/dashboard"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" />
                <path d="M3 9h18M9 21V9" />
              </svg>

              <p class="pl-2">Dashboard</p>
            </router-link>
          </li>
          <li class="w-full mt-1 hover:bg-gray-600">
            <router-link
              class="px-3 py-2 flex items-center justify-left"
              to="/inquiries"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class=""
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <line x1="8" y1="6" x2="21" y2="6"></line>
                <line x1="8" y1="12" x2="21" y2="12"></line>
                <line x1="8" y1="18" x2="21" y2="18"></line>
                <line x1="3" y1="6" x2="3.01" y2="6"></line>
                <line x1="3" y1="12" x2="3.01" y2="12"></line>
                <line x1="3" y1="18" x2="3.01" y2="18"></line>
              </svg>
              <p class="pl-2">Inquiries</p>
            </router-link>
          </li>

          <li class="w-full px-1 py-2">
            <p class="m-auto inset-0 pl-2 sidebar-heading">Stock</p>
          </li>

          <li class="w-full hover:bg-gray-600">
            <a class="flex items-center px-7 py-2" to="/dashboard">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"
                ></path>
                <line x1="7" y1="7" x2="7.01" y2="7"></line>
              </svg>
              <p class="pl-2">Items</p>
            </a>
          </li>

          <li class="w-full hover:bg-gray-600">
            <a class="flex items-center px-7 py-2" to="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <line x1="12" y1="1" x2="12" y2="23"></line>
                <path
                  d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"
                ></path>
              </svg>
              <p class="pl-2">Price List</p>
            </a>
          </li>

          <li class="w-full hover:bg-gray-600">
            <a class="flex items-center px-7 py-2" to="/dashboard">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                fill="none"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="3" y="3" width="7" height="7"></rect>
                <rect x="14" y="3" width="7" height="7"></rect>
                <rect x="14" y="14" width="7" height="7"></rect>
                <rect x="3" y="14" width="7" height="7"></rect>
              </svg>
              <p class="pl-2">Inventory</p>
            </a>
          </li>

          <li class="w-full px-1 py-2 sidebar-heading">
            <p class="m-auto inset-0 pl-2">Contacts</p>
          </li>
          <li class="w-full hover:bg-gray-600">
            <router-link
              class="px-7 py-2 flex items-center justify-left"
              to="/vendors"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="8.5" cy="7" r="4"></circle>
                <polyline points="17 11 19 13 23 9"></polyline>
              </svg>
              <p class="pl-2">Vendors</p>
            </router-link>
          </li>
          <li class="w-full hover:bg-gray-600">
            <router-link
              class="px-7 py-2 flex items-center justify-left"
              to="/customers"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
              <p class="pl-2">Customers</p>
            </router-link>
          </li>
          <li class="w-full hover:bg-gray-600">
            <router-link
              class="px-7 py-2 flex items-center justify-left"
              to="/customer-companies"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="16" x2="12" y2="12"></line>
                <line x1="12" y1="8" x2="12.01" y2="8"></line>
              </svg>
              <p class="pl-2">Customer Companies</p>
            </router-link>
          </li>

          <li class="w-full px-1 py-2 sidebar-heading">
            <p class="m-auto inset-0 pl-2">Docs</p>
          </li>
          <li class="w-full hover:bg-gray-600">
            <router-link
              class="px-7 py-2 flex items-center justify-left"
              to="/document-brands"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="16" x2="12" y2="12"></line>
                <line x1="12" y1="8" x2="12.01" y2="8"></line>
              </svg>
              <p class="pl-2">Brands</p>
            </router-link>
          </li>
          <li class="w-full hover:bg-gray-600">
            <router-link
              class="px-7 py-2 flex items-center justify-left"
              to="/catalogues"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                <path
                  d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
                ></path>
              </svg>
              <p class="pl-2">Catalogues & Brochures</p>
            </router-link>
          </li>
          <li class="w-full hover:bg-gray-600">
            <router-link
              class="px-7 py-2 flex items-center justify-left"
              to="/manuals"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"
                />
                <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8" />
              </svg>
              <p class="pl-2">Parts Manuals</p>
            </router-link>
          </li>

          <li class="w-full px-1 py-2 sidebar-heading">
            <p class="m-auto inset-0 pl-2">Sales</p>
          </li>

           <li class="w-full hover:bg-gray-600">
             <router-link
              class="flex items-center px-7 py-2"
              to="/tickets"
            >
           
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
              <p class="pl-2">Support Tickets</p>
             </router-link>
          </li>

          <li class="w-full hover:bg-gray-600">
            <a class="flex items-center px-7 py-2" to="/dashboard">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <polyline points="12 6 12 12 16 14"></polyline>
              </svg>
              <p class="pl-2">Estimates</p>
            </a>
          </li>

          <li class="w-full hover:bg-gray-600">
            <a class="flex items-center px-7 py-2" to="/dashboard">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"
                />
                <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8" />
              </svg>
              <p class="pl-2">Retainer Invoices</p>
            </a>
          </li>

          <li class="w-full hover:bg-gray-600">
            <a class="flex items-center px-7 py-2" to="/dashboard">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-file-report"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ffffff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="17" cy="17" r="4" />
                <path d="M17 13v4h4" />
                <path d="M12 3v4a1 1 0 0 0 1 1h4" />
                <path
                  d="M11.5 21h-6.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v2m0 3v4"
                />
              </svg>
              <p class="pl-2">Sales Orders</p>
            </a>
          </li>

          <li class="w-full hover:bg-gray-600">
            <a class="flex items-center px-7 py-2" to="/dashboard">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-file-invoice"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ffffff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                <path
                  d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
                />
                <line x1="9" y1="7" x2="10" y2="7" />
                <line x1="9" y1="13" x2="15" y2="13" />
                <line x1="13" y1="17" x2="15" y2="17" />
              </svg>
              <p class="pl-2">Invoices</p>
            </a>
          </li>

          <li class="w-full hover:bg-gray-600">
            <a class="flex items-center px-7 py-2" to="/dashboard">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                <polyline points="22 4 12 14.01 9 11.01"></polyline>
              </svg>
              <p class="pl-2">Delivery Notes</p>
            </a>
          </li>

          <li class="w-full hover:bg-gray-600">
            <a to="/dashboard" class="flex items-center px-7 py-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M3 3v18h18" />
                <path d="M18.7 8l-5.1 5.2-2.8-2.7L7 14.3" />
              </svg>
              <p class="pl-2">Reports</p>
            </a>
          </li>

          <!-- <li
            @click="dropDownVisible = true"
            class="w-full cursor-pointer hover:bg-gray-600 mt-1"
          >
            <a class="flex items-center px-3 py-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="12" cy="12" r="3"></circle>
                <path
                  d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
                ></path>
              </svg>
              <p class="pl-2">Settings</p>
            </a>
          </li> -->




 <li class="w-full px-1 py-2 sidebar-heading">
            <p class="m-auto inset-0 pl-2">Settings</p>
          </li>
          <li class="w-full hover:bg-gray-600">
            <router-link
              class="px-7 py-2 flex items-center justify-left"
              to="/categories"
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="12" cy="12" r="3"></circle>
                <path
                  d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
                ></path>
              </svg>
              <p class="pl-2">Categories</p>
            </router-link>
          </li>
           

           <li class="w-full hover:bg-gray-600">
            <router-link
              class="px-7 py-2 flex items-center justify-left"
              to="/users"
            >
             <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
              <p class="pl-2">Users</p>
            </router-link>
          </li>


          <li class="w-full hover:bg-gray-600">
            <router-link
              class="px-7 py-2 flex items-center justify-left"
              to="/trending-slides"
            >
               <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                <path
                  d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
                ></path>
              </svg>
              <p class="pl-2">Trending Slides</p>
            </router-link>
          </li>

          <li class="w-full hover:bg-gray-600">
            <router-link
              class="px-7 py-2 flex items-center justify-left"
              to="/masterkey" >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
              <p class="pl-2">Master Key</p>
            </router-link>
          </li>
          
          
          

          <li
            @click="sidebarCollapsed = !sidebarCollapsed"
            :class="{
              'sidebar-toggle-button__hidden': !sidebarCollapsed,
              'sidebar-toggle-button__visible': sidebarCollapsed,
            }"
            class="sidebar-toggle-button cursor-pointer px-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#ffffff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M15 18l-6-6 6-6" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div
      :style="{ marginLeft: contentAreaMarginLeft, width: contentAreaWidth }"
      class="bg-white content-area right-0"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/layout/TopBar";

export default {
  name: "MainLayout",
  data() {
    return { dropDownVisible: false };
  },

  computed: {
    sidebarCollapsed: {
      get() {
        return this.$store.state.ui.sidebarCollapsed;
      },
      set(newVal) {
        this.$store.commit("SET_SIDEBAR_COLLAPSE_STATE", newVal);
      },
    },
    sidebarMaxWidth() {
      this.sidebarCollapsed;
      if (!this.$refs.sidebar) return "none";
      if (this.sidebarCollapsed) return 4 + "rem";
      else return this.$refs.sidebar.scrollWidth + "rem";
    },
    contentAreaMarginLeft() {
      this.sidebarCollapsed;
      if (!this.$refs.sidebar) return "16.5%";

      if (this.sidebarCollapsed) return "4rem";
      else return "16.5%";
    },
    contentAreaWidth() {
      this.sidebarCollapsed;
      if (!this.$refs.sidebar) return "83.5%";

      if (this.sidebarCollapsed) return "calc(100% - 4rem)";
      else return "83.5%";
    },
    topBarLeftValue() {
      this.sidebarCollapsed;
      if (!this.$refs.sidebar) return "auto";

      if (this.sidebarCollapsed) return "4rem";
      else return "auto";
    },
  },
  components: {
    TopBar,
  },
};
</script>

<style lang="scss" scoped>
.sidebar-toggle-button {
  padding: 1rem;

  // &::after {
  //   content: "\2190";
  //   color: white;
  //   font-size: 2rem;
  // }
}

.sidebar-toggle-button__hidden {
  transform: rotate(0deg);
}
.sidebar-toggle-button__visible {
  transform: rotate(180deg);
}

.sidebar {
  overflow: auto;
  width: 16.5%;
  height: 100vh;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  transition: max-width 0.5s ease-in-out;

  // &::after {
  //   border-radius: 1000px;
  //   content: "\2190";
  //   font-size: 2rem;
  //   color: white;
  //   position: absolute;
  //   cursor: pointer;
  //   padding: 1rem;
  //   display: inline-block;
  // }
}

.sidebar-collapsed {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;

  li {
    position: relative;

    // &::after {
    //   content: "--";
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    // }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li > p {
    display: none;
  }

  a {
    padding: 0.64rem !important;
    p {
      display: none;
    }
  }

  @media (max-width: 1300px) {
    a {
      padding: 0.7rem !important;
    }
    svg {
      width: 1rem;
      height: 1rem;
    }

    .sidebar-heading {
      display: none;
    }
  }

  // * {
  //   visibility: hidden;
  // }

  // a {
  //   visibility: visible;
  //   svg {
  //     background: pink;
  //   }
  // }
}

.content-area {
  transition: margin-left 0.5s ease-in-out;
  height: calc(100vh - 5.9rem);
}

.dropDownSettings {
  position: absolute;

  z-index: 99999;
  width: 56%;
  bottom: -215px;
  left: 100px;
  margin-left: 10px;
}
.dropDownOverlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.router-link-active li,
.router-link-active {
  background-color: transparent;
}
</style>