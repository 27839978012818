import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";

import MainLayout from "@/components/layout/MainLayout";
import LoginLayout from "@/components/layout/LoginLayout";
import SearchLoadingOverlay from "@/components/base/SearchLoadingOverlay";
import errorHandler from "@/utils/error";

import "@/tailwind/index.tailwind.css";

const app = createApp(App)
  .use(store)
  .use(router);

app.component("MainLayout", MainLayout);
app.component("LoginLayout", LoginLayout);
app.component("SearchLoadingOverlay", SearchLoadingOverlay);

app.config.globalProperties.$moment = moment;
app.config.globalProperties.$errorHandler = errorHandler;

app.mount("#app");
