<template>
  <div
    :style="{ left: leftValue, width }"
    class="
      border-t-0 border-r-0 border-l-0 border
      topbar
      flex-1 flex flex-col
      w-full
      fixed
      right-0
      z-10
    "
  >
    <nav class="px-4 flex justify-between bg-white h-14">
      <!-- top bar left -->
      <ul class="flex items-center">
        <!-- add button -->
        <li>
          <p style="color: #cfb47e" class="text-2xl">{{ $route.name }}</p>
        </li>
      </ul>

      <!-- to bar right  -->
      <ul class="flex items-center relative">
        <li class="pr-6">
          <svg
            style="color: #cfb47e"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-bell"
          >
            <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
            <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
          </svg>
        </li>
        <li @click="dropDownVisible = true" class="cursor-pointer h-10 w-10">
          <img class="h-full w-full rounded-full mx-auto" src="/user-pic.jpg" />
        </li>
        <li @click="dropDownVisible = true" class="cursor-pointer pl-2">
          <p
            v-if="$store.getters.currentUser"
            class="text-black font-medium text-sm"
          >
            {{ $store.getters.currentUser.name }}
          </p>
          <p v-if="$store.getters.currentUser" class="text-gray-400 text-xs">
            {{ $store.getters.currentUser.email }}
          </p>
        </li>
        <div
          v-if="dropDownVisible"
          @click="dropDownVisible = false"
          class="dropDownOverlay"
        ></div>
        <div
          v-if="dropDownVisible"
          class="dropDownLogout bg-white shadow rounded"
        >
          <ul>
            <li
              @click="$store.dispatch('logout'), (dropDownVisible = false)"
              class="hover:bg-gray-100 px-4 py-2 cursor-pointer"
            >
              Logout
            </li>
          </ul>
        </div>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: ["leftValue", "width"],
  name: "TopBar",
  data() {
    return {
      dropDownVisible: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  z-index: 1000;
}
.dropDownLogout {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 9999;
}
.dropDownOverlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9998;
}
</style>