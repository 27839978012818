import types from "@/utils/mutation-types";
import alertTypes from "@/utils/alert-types";
import axios from "@/utils/axios";
import errorHandler from "@/utils/error";

const catalogueStore = {
  state: () => ({
    catalogues: [],
  }),
  mutations: {
    [types.SET_CATALOGUES](state, catalogues) {
      state.catalogues = catalogues;
    },
    [types.ADD_CATALOGUE_TO_LIST](state, catalogue) {
      state.catalogues.unshift(catalogue);
    },
    [types.REMOVE_CATALOGUE_FROM_LIST](state, catalogueId) {
      state.catalogues = state.catalogues.filter((c) => c._id !== catalogueId);
    },
  },
  actions: {
    async getCatalogues({ commit }, { brand } = {}) {
      try {
        let uri = `${process.env.VUE_APP_API_HOST}/api/v1/catalogues`;

        if (brand) uri = `${uri}?brand=${brand}`;

        const res = await axios.get(uri);
        if (res.status === 200) {
          commit(types.SET_CATALOGUES, res.data.catalogues);
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },
    async deleteCatalogue({ commit, getters, dispatch }, id) {
      try {
        const res = await axios.delete(
          `${process.env.VUE_APP_API_HOST}/api/v1/catalogues/${id}`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );
        if (res.status === 200) {
          commit(types.REMOVE_CATALOGUE_FROM_LIST, res.data.catalogue._id);
          dispatch("addAlert", {
            ...alertTypes.warning,
            alert: "catalogue deleted",
          });
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },
    async addCatalogue({ commit, getters, dispatch }, data) {
      try {
        const { newCatalogue, files } = data;

        // Upload Thumbnail
        const thumbnailFd = new FormData();
        thumbnailFd.append("file", files.thumbnail);
        newCatalogue.thumbnail = await dispatch("singleUpload", thumbnailFd);

        // Upload file
        const fileFd = new FormData();
        fileFd.append("file", files.file);
        newCatalogue.file = await dispatch("singleUpload", fileFd);

        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/catalogues`,
          newCatalogue,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 201) {
          commit(types.ADD_CATALOGUE_TO_LIST, res.data.catalogue);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "catalogue added",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
  },
  getters: {
    catalogues(state) {
      return state.catalogues;
    },
  },
};

export default catalogueStore;
