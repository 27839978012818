import axios from "@/utils/axios";
import types from "@/utils/mutation-types";
import alertTypes from "@/utils/alert-types";
import router from "@/router";

import errorHandler from "@/utils/error";

const authStore = {
  state: () => ({
    authToken: localStorage.getItem("current_user") ? "randomstring" : "",
    currentUser: JSON.parse(localStorage.getItem("current_user")) || null,
  }),
  mutations: {
    //   Set Auth token
    [types.SET_AUTH_TOKEN](state, token) {
      state.authToken = token;
      localStorage.setItem("auth_token", token);
    },

    //   Set current user
    [types.SET_CURRENT_USER](state, user) {
      state.currentUser = user;
      localStorage.setItem("current_user", JSON.stringify(user));
    },

    // Remove auth token
    [types.REMOVE_AUTH_TOKEN](state) {
      localStorage.removeItem("auth_token");
      state.authToken = "";
    },

    // Remove current user
    [types.REMOVE_CURRENT_USER](state) {
      localStorage.removeItem("current_user");
      state.currentUser = "";
    },
  },
  actions: {
    //   Authenticate user
    async authenticate({ commit, dispatch }, user) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/auth`,
          { user }
        );
        if (res.status == 200) {
          commit(types.SET_CURRENT_USER, res.data.user);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "login successful",
          });

          router.go("/dashboard");
        }
      } catch (e) {
        // HANDLE ERROR
        errorHandler(e);
      }
    },
    async logout({ commit }) {
      try {
        await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/auth/logout`,
          {}
        );
        commit(types.REMOVE_CURRENT_USER);
        router.go("/");
      } catch (e) {
        errorHandler(e);
      }
    },
    // Forgot Password
    async forgotPassword({ dispatch }, user) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/auth/forgot-password`,
          { user }
        );
        if (res.status == 200) {
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "Reset link sent.",
          });
          router.push("/");
        }
      } catch (e) {
        // HANDLE ERROR
        errorHandler(e);
      }
    },
    // Reset Password
    async resetPassword({ dispatch }, user) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/auth/reset-password`,
          { user }
        );
        if (res.status == 200) {
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "Password reset successful.",
          });
          router.push("/");
        }
      } catch (e) {
        // HANDLE ERROR
        errorHandler(e);
      }
    },
  },
  getters: {
    bearerToken(state) {
      return `bearer ${state.authToken}`;
    },
    isAuthenticated(state) {
      if (state.authToken) return true;
      else return false;
    },
    currentUser(state) {
      return state.currentUser;
    },
  },
};

export default authStore;
