export default {
  // Auth
  SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  REMOVE_AUTH_TOKEN: 'REMOVE_AUTH_TOKEN',
  REMOVE_CURRENT_USER: 'REMOVE_CURRENT_USER',

  // Alerts
  APPEND_ALERTS: 'APPEND_ALERTS',
  REMOVE_ALERT: 'REMOVE_ALERT',

  // Inquiries
  SET_INQUIRIES: 'SET_INQUIRIES',
  SET_PAGE: 'SET_PAGE',
  SET_TOTAL_PAGES: 'SET_TOTAL_PAGES',
  SET_TOTAL_INQUIRIES: 'SET_TOTAL_INQUIRIES',
  SET_INQUIRY_SEARCH: 'SET_INQUIRY_SEARCH',
  UPDATE_INQUIRY_IN_LIST: 'UPDATE_INQUIRY_IN_LIST',
  REMOVE_INQUIRY_FROM_LIST: 'REMOVE_INQUIRY_FROM_LIST',
  SET_SELECTED_INQUIRY: 'SET_SELECTED_INQUIRY',
  SET_LAST_VIEWED_INQUIRY: 'SET_LAST_VIEWED_INQUIRY',
  SET_FILTERS: 'SET_FILTERS',

  // Categories
  SET_CATEGORIES: 'SET_CATEGORIES',
  ADD_CATEGORY_TO_LIST: 'ADD_CATEGORY_TO_LIST',
  REMOVE_CATEGORY_FROM_LIST: 'REMOVE_CATEGORY_FROM_LIST',
  REPLACE_CATEGORY_IN_LIST: 'REPLACE_CATEGORY_IN_LIST',

  // Branches
  SET_BRANCHES: 'SET_BRANCHES',

  // Customers
  SET_CUSTOMERS: 'SET_CUSTOMERS',
  SET_ALL_CUSTOMERS: 'SET_ALL_CUSTOMERS',
  SET_CUSTOMERS_SEARCH: 'SET_CUSTOMERS_SEARCH',
  SET_CUSTOMERS_PAGE: 'SET_CUSTOMERS_PAGE',
  SET_CUSTOMERS_TOTAL_PAGES: 'SET_CUSTOMERS_TOTAL_PAGES',
  SET_TOTAL_CUSTOMERS: 'SET_TOTAL_CUSTOMERS',
  ADD_CUSTOMER_TO_LIST: 'ADD_CUSTOMER_TO_LIST',
  UPDATE_CUSTOMER_IN_LIST: 'UPDATE_CUSTOMER_IN_LIST',
  REMOVE_CUSTOMER_FROM_LIST: 'REMOVE_CUSTOMER_FROM_LIST',

  // Customer Companies
  SET_CUSTOMER_COMPANIES: 'SET_CUSTOMER_COMPANIES',
  ADD_CUSTOMER_COMPANY_TO_LIST: 'ADD_CUSTOMER_COMPANY_TO_LIST',
  UPDATE_CUSTOMER_COMPANY_IN_LIST: 'UPDATE_CUSTOMER_COMPANY_IN_LIST',
  REMOVE_CUSTOMER_COMPANY_FROM_LIST: 'REMOVE_CUSTOMER_COMPANY_FROM_LIST',

  // Vendors
  SET_VENDORS: 'SET_VENDORS',
  ADD_VENDOR_TO_LIST: 'ADD_VENDOR_TO_LIST',
  UPDATE_VENDOR_IN_LIST: 'UPDATE_VENDOR_IN_LIST',
  SET_VENDORS_SEARCH: 'SET_VENDORS_SEARCH',
  SET_VENDORS_PAGE: 'SET_VENDORS_PAGE',
  SET_VENDORS_TOTAL_PAGES: 'SET_VENDORS_TOTAL_PAGES',
  SET_TOTAL_VENDORS: 'SET_TOTAL_VENDORS',
  REMOVE_VENDOR_FROM_LIST: 'REMOVE_VENDOR_FROM_LIST',

  // Users
  SET_USERS: 'SET_USERS',
  ADD_USER_TO_LIST: 'ADD_USER_TO_LIST',
  REMOVE_USER_FROM_LIST: 'REMOVE_USER_FROM_LIST',
  UPDATE_USER_IN_LIST: 'UPDATE_USER_IN_LIST',

  // Document Brands
  SET_DOC_BRANDS: 'SET_DOC_BRANDS',
  ADD_DOC_BRAND_TO_LIST: 'ADD_DOC_BRAND_TO_LIST',
  UPDATE_DOC_BRAND_IN_LIST: 'UPDATE_DOC_BRAND_IN_LIST',
  REMOVE_DOC_BRAND_FROM_LIST: 'REMOVE_DOC_BRAND_FROM_LIST',

  // Catalogues
  SET_CATALOGUES: 'SET_CATALOGUES',
  ADD_CATALOGUE_TO_LIST: 'ADD_CATALOGUE_TO_LIST',
  REMOVE_CATALOGUE_FROM_LIST: 'REMOVE_CATALOGUE_FROM_LIST',

  // Part Manuals
  SET_MANUALS: 'SET_MANUALS',
  ADD_MANUAL_TO_LIST: 'ADD_MANUAL_TO_LIST',
  REMOVE_MANUAL_FROM_LIST: 'REMOVE_MANUAL_FROM_LIST',

  // Manual Folders
  SET_MANUAL_FOLDERS: 'SET_MANUAL_FOLDERS',
  ADD_MANUAL_FOLDER_TO_LIST: 'ADD_MANUAL_FOLDER_TO_LIST',
  UPDATE_MANUAL_FOLDER_IN_LIST: 'UPDATE_MANUAL_FOLDER_IN_LIST',
  REMOVE_MANUAL_FOLDER_FROM_LIST: 'REMOVE_MANUAL_FOLDER_FROM_LIST',

  // Trending Slides
  SET_TSLIDES: 'SET_TSLIDES',
  ADD_TSLIDE_TO_LIST: 'ADD_TSLIDE_TO_LIST',
  REMOVE_TSLIDE_FROM_LIST: 'REMOVE_TSLIDE_FROM_LIST',

  // Support Tickets
  SET_SUPPORT_TICKETS: 'SET_SUPPORT_TICKETS',
  ADD_SUPPORT_TICKET: 'ADD_SUPPORT_TICKET',
  UPDATE_SUPPORT_TICKET: 'UPDATE_SUPPORT_TICKET',

  // UI
  SET_SIDEBAR_COLLAPSE_STATE: 'SET_SIDEBAR_COLLAPSE_STATE',
};
