import axios from "@/utils/axios";
import types from "@/utils/mutation-types";
import alertTypes from "@/utils/alert-types";
import errorHandler from "@/utils/error";

const inquiryStore = {
  state: () => ({
    inquiries: [],
    selectedInquiry: "",
    page: 1,
    totalPages: 0,
    filters: [],
    inquirySearchString: "",
    lastViewedInquiry: "",
  }),
  mutations: {
    // Set inquiries array
    [types.SET_INQUIRIES](state, data) {
      state.inquiries = data;
    },
    [types.SET_SELECTED_INQUIRY](state, data) {
      state.selectedInquiry = data;
    },
    [types.SET_LAST_VIEWED_INQUIRY](state, data) {
      state.lastViewedInquiry = data;
    },
    // Update an existing inquiry after an update
    [types.UPDATE_INQUIRY_IN_LIST](state, inquiry) {
      state.inquiries = state.inquiries.map((inq) => {
        if (inq._id === inquiry._id) return inquiry;
        else return inq;
      });
      // console.log(state.inquiries);
    },
    // Remove an inquiry from the list of inquiries
    [types.REMOVE_INQUIRY_FROM_LIST](state, id) {
      console.log(state.inquiries.length);
      state.inquiries = state.inquiries.filter((inq) => {
        return inq._id != id;
      });
    },
    // set Search value
    [types.SET_INQUIRY_SEARCH](state, data) {
      state.inquirySearchString = data;
    },
    //   Set Page
    [types.SET_PAGE](state, page) {
      state.page = page;
    },
    [types.SET_TOTAL_PAGES](state, numberOfPages) {
      state.totalPages = numberOfPages;
    },
    [types.SET_TOTAL_INQUIRIES](state, totalInquiries) {
      state.totalInquiries = totalInquiries;
    },
    [types.SET_FILTERS](state, filters) {
      state.filters = filters;
    },
  },
  actions: {
    setLastVisitedInquiry({ commit }, id) {
      commit(types.SET_LAST_VIEWED_INQUIRY, id);

      setTimeout(() => {
        commit(types.SET_LAST_VIEWED_INQUIRY, "");
      }, 10000);
    },
    async getInquiries({ getters, commit }) {
      try {
        const page = getters.currentInquiryPage;
        const queryPage = page || 1;

        let uri = `${process.env.VUE_APP_API_HOST}/api/v1/inquiries?page=${queryPage}`;

        if (getters.inquirySearchString) {
          uri = `${uri}&search=${getters.inquirySearchString}`;
        }

        if (getters.filters.length) {
          getters.filters.forEach((filter) => {
            uri = `${uri}&${filter.type}=${filter.value}`;
          });
        }

        const res = await axios.get(uri, {
          headers: { authorization: getters.bearerToken },
        });

        if (res.status === 200) {
          commit(types.SET_INQUIRIES, res.data.inquiries);
          commit(types.SET_TOTAL_PAGES, res.data.totalPages);
          commit(types.SET_TOTAL_INQUIRIES, res.data.totalInquiries);
          commit(types.SET_PAGE, res.data.page);
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },
    async getSingleInquiry({ commit, getters }, id) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_HOST}/api/v1/inquiries/${id}`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );
        if (res.status == 200) {
          commit(types.SET_SELECTED_INQUIRY, res.data.inquiry);
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },
    async addInquiry({ getters, dispatch }, inquiry) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/inquiries`,
          { inquiry },
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 201) {
          await dispatch("getInquiries");
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "inquiry added!",
          });
        }
      } catch (e) {
        //   Handle Error
        console.log(e);
        errorHandler(e);
      }
    },
    async editInquiry(
      { getters, dispatch, commit },
      { id, inquiry, files, deletedFiles }
    ) {
      try {
        if (files && files.length) {
          for (const fileToUpload of files) {
            const fd = new FormData();
            fd.append("file", fileToUpload);
            const filename = await dispatch("singleUpload", fd);

            inquiry.customerDocs.push({
              file: filename,
              name: fileToUpload.name,
            });
          }
        }

        if (deletedFiles && deletedFiles.length) {
          if (inquiry.customerDocs && inquiry.customerDocs.length) {
            deletedFiles.forEach((dfile) => {
              inquiry.customerDocs = inquiry.customerDocs.filter((doc) => {
                return doc.file !== dfile;
              });
            });
          }
        }
        console.log(inquiry.customerDocs);
        const res = await axios.patch(
          `${process.env.VUE_APP_API_HOST}/api/v1/inquiries/${id}`,
          inquiry,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.UPDATE_INQUIRY_IN_LIST, res.data.inquiry);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "inquiry updated!",
          });
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },
    async deleteInquiry({ getters, commit, dispatch }, id) {
      try {
        const res = await axios.delete(
          `${process.env.VUE_APP_API_HOST}/api/v1/inquiries/${id}`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.REMOVE_INQUIRY_FROM_LIST, res.data.inquiryId);
          dispatch("addAlert", {
            ...alertTypes.warning,
            alert: "inquiry deleted!",
          });
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },
    async setInquiryStatus(
      { getters, commit, dispatch },
      { id, inquiryStatus }
    ) {
      try {
        const uri = `${process.env.VUE_APP_API_HOST}/api/v1/inquiries/${id}/set-inquiry-status`;
        const res = await axios.post(
          uri,
          { inquiryStatus },
          { headers: { authorization: getters.bearerToken } }
        );

        if (res.status === 200) {
          commit(types.UPDATE_INQUIRY_IN_LIST, res.data.inquiry);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "inquiry status updated",
          });
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },

    async setInquiryPriority(
      { getters, commit, dispatch },
      { id, inquiryPriority }
    ) {
      try {
        const uri = `${process.env.VUE_APP_API_HOST}/api/v1/inquiries/${id}/set-inquiry-priority`;
        const res = await axios.post(
          uri,
          { inquiryPriority },
          { headers: { authorization: getters.bearerToken } }
        );

        if (res.status === 200) {
          commit(types.UPDATE_INQUIRY_IN_LIST, res.data.inquiry);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "inquiry priority updated",
          });
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },
    async setCustomerStatus(
      { getters, commit, dispatch },
      { id, customerStatus }
    ) {
      try {
        const uri = `${process.env.VUE_APP_API_HOST}/api/v1/inquiries/${id}/set-customer-status`;
        const res = await axios.post(
          uri,
          { customerStatus },
          { headers: { authorization: getters.bearerToken } }
        );

        if (res.status === 200) {
          commit(types.UPDATE_INQUIRY_IN_LIST, res.data.inquiry);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "inquiry status updated",
          });
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },
    async addStatusHistoryItem({ getters, commit, dispatch }, options) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/inquiries/${options.id}/statushistory`,
          options.newItem,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.UPDATE_INQUIRY_IN_LIST, res.data.inquiry);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "status history updated",
          });
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },
    async addTrackingData({ getters, commit, dispatch }, options) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/inquiries/${options.id}/tracking`,
          options.newItem,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.UPDATE_INQUIRY_IN_LIST, res.data.inquiry);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "tracking updated",
          });
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },
    async deleteTrackingData({ getters, commit, dispatch }, options) {
      try {
        const res = await axios.delete(
          `${process.env.VUE_APP_API_HOST}/api/v1/inquiries/${options.id}/tracking/${options.trackingDataId}`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.UPDATE_INQUIRY_IN_LIST, res.data.inquiry);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "tracking updated",
          });
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },
    async addPayment({ getters, commit, dispatch }, options) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/inquiries/${options.id}/payments`,
          options.newPayment,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.UPDATE_INQUIRY_IN_LIST, res.data.inquiry);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "payment added",
          });
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },
    async deletePayment({ getters, commit, dispatch }, options) {
      try {
        const res = await axios.delete(
          `${process.env.VUE_APP_API_HOST}/api/v1/inquiries/${options.inquiryId}/payments/${options.paymentId}`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        console.log(res);

        if (res.status === 200) {
          commit(types.UPDATE_INQUIRY_IN_LIST, res.data.inquiry);
          dispatch("addAlert", {
            ...alertTypes.warning,
            alert: "payment deleted",
          });
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },
    async addVendor({ dispatch, getters }, { id, newVendor }) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/inquiries/${id}/vendors`,
          {
            newVendor,
          },
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "vendor added successfully",
          });
        }
        return;
      } catch (e) {
        console.log(e);
        errorHandler(e);
      }
    },
    async editVendor({ getters, dispatch }, { id, vendor }) {
      try {
        if (vendor.items && vendor.items.length) {
          const hasFiles = [];

          vendor.items.forEach((item) => {
            if (item.files && item.files.length) {
              hasFiles.push(item);
            }
          });

          if (hasFiles.length) {
            for (const item of hasFiles) {
              item.images = item.images || [];
              if (item.files.length) {
                const fd = new FormData();
                item.files.forEach((file) => {
                  fd.append("files", file);
                });

                const filenames = await dispatch("multipleUpload", fd);

                item.images = [...item.images, ...filenames];
              }
            }
          }
        }

        if (vendor.files && vendor.files.length) {
          for (const fileToUpload of vendor.files) {
            const fd = new FormData();
            fd.append("file", fileToUpload);
            const filename = await dispatch("singleUpload", fd);

            vendor.documents.push({
              file: filename,
              name: fileToUpload.name,
            });
          }
        }

        const res = await axios.patch(
          `${process.env.VUE_APP_API_HOST}/api/v1/inquiries/${id}/vendors/${vendor._id}`,
          {
            vendor,
          },
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "vendor updated successfully",
          });
        }

        return;
      } catch (e) {
        console.log(e);
        errorHandler(e);
      }
    },
    async deleteVendor({ getters, dispatch }, { id, vendor }) {
      try {
        console.log("hello");
        const res = await axios.delete(
          `${process.env.VUE_APP_API_HOST}/api/v1/inquiries/${id}/vendors/${vendor._id}`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          dispatch("getSingleInquiry", getters.selectedInquiry._id);
          dispatch("addAlert", {
            ...alertTypes.warning,
            alert: "vendor deleted!",
          });
        }

        return;
      } catch (e) {
        console.log(e);
        errorHandler(e);
      }
    },
  },
  getters: {
    inquiries(state) {
      return state.inquiries;
    },
    selectedInquiry(state) {
      return state.selectedInquiry;
    },
    totalPages(state) {
      return state.totalPages;
    },
    currentInquiryPage(state) {
      return state.page;
    },

    inquirySearchString(state) {
      return state.inquirySearchString;
    },
    lastViewedInquiry(state) {
      return state.lastViewedInquiry;
    },
    filters(state) {
      return state.filters;
    },
  },
};

export default inquiryStore;
