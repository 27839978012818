import types from "@/utils/mutation-types";
import axios from "@/utils/axios";
import errorHandler from "@/utils/error";
import alertTypes from "@/utils/alert-types";

const categoriesStore = {
  state: () => ({
    categories: [],
  }),
  mutations: {
    [types.SET_CATEGORIES](state, categories) {
      state.categories = categories;
    },
    [types.ADD_CATEGORY_TO_LIST](state, category) {
      state.categories.unshift(category);
    },
    [types.REMOVE_CATEGORY_FROM_LIST](state, category) {
      state.categories = state.categories.filter((c) => c._id !== category._id);
    },
    [types.REPLACE_CATEGORY_IN_LIST](state, category) {
      state.categories = state.categories.map((c) => {
        if (c._id !== category._id) return c;
        else return category;
      });
    },
  },
  actions: {
    async getCategories({ commit, getters }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_HOST}/api/v1/categories`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.SET_CATEGORIES, res.data.categories);
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async addCategory({ commit, getters, dispatch }, category) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/categories`,
          category,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 201) {
          commit(types.ADD_CATEGORY_TO_LIST, res.data.category);
          dispatch("addAlert", {
            ...alertTypes.succes,
            alert: "category added",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async editCategory({ commit, getters, dispatch }, category) {
      try {
        const res = await axios.patch(
          `${process.env.VUE_APP_API_HOST}/api/v1/categories/${category._id}`,
          category,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.REPLACE_CATEGORY_IN_LIST, res.data.category);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "category updated",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async deleteCategory({ commit, getters, dispatch }, category) {
      try {
        const res = await axios.delete(
          `${process.env.VUE_APP_API_HOST}/api/v1/categories/${category._id}`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.REMOVE_CATEGORY_FROM_LIST, res.data.category);
          dispatch("addAlert", {
            ...alertTypes.warning,
            alert: "category deleted",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
  },
  getters: {
    categories(state) {
      return state.categories;
    },
  },
};

export default categoriesStore;
