import types from "@/utils/mutation-types";
import alertTypes from "@/utils/alert-types";
import axios from "@/utils/axios";
import errorHandler from "@/utils/error";

const brandStore = {
  state: () => ({
    brands: [],
  }),
  mutations: {
    [types.SET_DOC_BRANDS](state, brands) {
      state.brands = brands;
    },
    [types.ADD_DOC_BRAND_TO_LIST](state, brand) {
      state.brands.unshift(brand);
    },
    [types.UPDATE_DOC_BRAND_IN_LIST](state, brand) {
      state.brands = state.brands.map((b) => {
        if (b._id === brand._id) {
          return brand;
        } else {
          return b;
        }
      });
    },
    [types.REMOVE_DOC_BRAND_FROM_LIST](state, brandId) {
      state.brands = state.brands.filter((c) => c._id !== brandId);
    },
  },
  actions: {
    async getDocumentBrands({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_HOST}/api/v1/documentbrands`
        );
        if (res.status === 200) {
          commit(types.SET_DOC_BRANDS, res.data.brands);
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async deleteDocumentBrand({ commit, getters, dispatch }, id) {
      try {
        const res = await axios.delete(
          `${process.env.VUE_APP_API_HOST}/api/v1/documentbrands/${id}`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );
        if (res.status === 200) {
          commit(types.REMOVE_DOC_BRAND_FROM_LIST, res.data.brand._id);
          dispatch("addAlert", {
            ...alertTypes.warning,
            alert: "brand deleted",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async addDocumentBrand({ commit, getters, dispatch }, data) {
      try {
        const { brand, logo } = data;

        // Upload file
        const fileFd = new FormData();
        fileFd.append("file", logo);
        brand.logo = await dispatch("singleUpload", fileFd);

        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/documentbrands`,
          brand,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 201) {
          commit(types.ADD_DOC_BRAND_TO_LIST, res.data.brand);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "brand added",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async editDocumentBrand({ commit, getters, dispatch }, data) {
      try {
        const { brand, logo } = data;

        // Upload file
        if (logo) {
          const fileFd = new FormData();
          fileFd.append("file", logo);
          brand.logo = await dispatch("singleUpload", fileFd);
        }

        const res = await axios.patch(
          `${process.env.VUE_APP_API_HOST}/api/v1/documentbrands/${brand._id}`,
          brand,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.UPDATE_DOC_BRAND_IN_LIST, res.data.brand);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "brand updated",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
  },
  getters: {
    documentBrands(state) {
      return state.brands;
    },
  },
};

export default brandStore;
