import types from "@/utils/mutation-types";
import axios from "@/utils/axios";
import errorHandler from "@/utils/error";
import alertTypes from "@/utils/alert-types";

const trendingSlideStore = {
  state: () => ({
    slides: [],
  }),
  mutations: {
    [types.SET_TSLIDES](state, slides) {
      state.slides = slides;
    },
    [types.ADD_TSLIDE_TO_LIST](state, slide) {
      state.slides.unshift(slide);
    },
    [types.REMOVE_TSLIDE_FROM_LIST](state, slide) {
      state.slides = state.slides.filter((s) => s._id !== slide._id);
    },
  },
  actions: {
    async getTrendingSlides({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_HOST}/api/v1/trendingslides`
        );

        if (res.status === 200) {
          commit(types.SET_TSLIDES, res.data.slides);
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async addTrendingSlide({ commit, dispatch }, data = {}) {
      try {
        const { newSlide, files } = data;

        // Upload Thumbnail
        const thumbnailFd = new FormData();
        thumbnailFd.append("file", files.thumbnail);
        newSlide.image = await dispatch("singleUpload", thumbnailFd);

        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/trendingslides`,
          newSlide
        );

        if (res.status === 201) {
          commit(types.ADD_TSLIDE_TO_LIST, res.data.slide);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "slide added",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async deleteTrendingSlide({ commit, dispatch }, slide) {
      try {
        console.log(slide);
        const res = await axios.delete(
          `${process.env.VUE_APP_API_HOST}/api/v1/trendingslides/${slide}`
        );

        if (res.status === 200) {
          commit(types.REMOVE_TSLIDE_FROM_LIST, res.data.slide);
          dispatch("addAlert", {
            ...alertTypes.warning,
            alert: "slide deleted",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
  },
  getters: {
    trendingSlides(state) {
      return state.slides;
    },
  },
};

export default trendingSlideStore;
