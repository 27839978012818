import types from "@/utils/mutation-types";
import alertTypes from "@/utils/alert-types";
import axios from "@/utils/axios";
import errorHandler from "@/utils/error";

const brandStore = {
  state: () => ({
    folders: [],
  }),
  mutations: {
    [types.SET_MANUAL_FOLDERS](state, folders) {
      state.folders = folders;
    },
    [types.ADD_MANUAL_FOLDER_TO_LIST](state, folder) {
      state.folders.unshift(folder);
    },
    [types.UPDATE_MANUAL_FOLDER_IN_LIST](state, folder) {
      state.folders = state.folders.map((f) => {
        if (f._id === folder._id) return folder;
        else return f;
      });
    },
    [types.REMOVE_MANUAL_FOLDER_FROM_LIST](state, folderId) {
      state.folders = state.folders.filter((c) => c._id !== folderId);
    },
  },
  actions: {
    async getManualFolders({ commit, dispatch }, { brand, folder } = {}) {
      try {
        let uri = `${process.env.VUE_APP_API_HOST}/api/v1/manualfolders`;

        if (brand) uri = `${uri}?brand=${brand}`;

        if (folder) uri = `${uri}&folder=${folder}`;

        const res = await axios.get(uri);
        if (res.status === 200) {
          commit(types.SET_MANUAL_FOLDERS, res.data.folders);
          dispatch("getManuals", {
            folderid: folder,
            brand,
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async deleteManualFolder({ commit, getters, dispatch }, id) {
      try {
        const res = await axios.delete(
          `${process.env.VUE_APP_API_HOST}/api/v1/manualfolders/${id}`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );
        if (res.status === 200) {
          commit(types.REMOVE_MANUAL_FOLDER_FROM_LIST, res.data.folder._id);
          dispatch("addAlert", {
            ...alertTypes.warning,
            alert: "folder deleted",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async addManualFolder(
      { commit, getters, dispatch },
      { folder, brandid, folderid } = {}
    ) {
      try {
        folder.brand = brandid;

        if (folderid) folder.folder = folderid;

        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/manualfolders`,
          folder,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 201) {
          commit(types.ADD_MANUAL_FOLDER_TO_LIST, res.data.folder);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "folder added",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async editManualFolder(
      { commit, getters, dispatch },
      { folder, brandid } = {}
    ) {
      try {
        folder.brand = brandid;

        const res = await axios.patch(
          `${process.env.VUE_APP_API_HOST}/api/v1/manualfolders/${folder._id}`,
          folder,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.UPDATE_MANUAL_FOLDER_IN_LIST, res.data.folder);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "folder added",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
  },
  getters: {
    manualFolders(state) {
      return state.folders;
    },
  },
};

export default brandStore;
