<template>
  <div class="search-overlay search-overlay-animate"></div>
</template>

<script>
export default {
  name: "SearchLoadingOverlay",
};
</script>

<style scoped>
.search-overlay {
  overflow: auto;
  position: absolute;
  z-index: 10;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.9;
}

.search-overlay-animate {
  background: linear-gradient(91deg, #e0e0e0, #ffffff, #c2c2c2);
  background-size: 600% 600%;

  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 52%;
  }
  50% {
    background-position: 100% 49%;
  }
  100% {
    background-position: 0% 52%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 52%;
  }
  50% {
    background-position: 100% 49%;
  }
  100% {
    background-position: 0% 52%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 52%;
  }
  50% {
    background-position: 100% 49%;
  }
  100% {
    background-position: 0% 52%;
  }
}
</style>