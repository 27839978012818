import errorHandler from "@/utils/error";
import axios from "@/utils/axios";

const uploadStore = {
  state: () => ({}),
  actions: {
    // The "file/files" is a FormData Object field name is either "files" or "file"
    async singleUpload({ getters }, file) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/upload/single`,
          file,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          return res.data.filename;
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    // The "file/files" is a FormData Object field name is either "files" or "file"
    async multipleUpload({ getters }, files) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/upload/multiple`,
          files,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          return res.data.filenames;
        }
      } catch (e) {
        errorHandler(e);
      }
    },
  },
};

export default uploadStore;
