import router from "@/router";
import store from "@/store";
import alertTypes from "@/utils/alert-types";
import mutationTypes from "@/utils/mutation-types";

const errorHandler = (e) => {
  console.log(e);
  if (!e.response || e.response.status === 500) {
    return store.dispatch("addAlert", {
      ...alertTypes.danger,
      alert: "something has gone wrong",
    });
  }

  if (e.response) {
    if (e.response.status === 403) {
      store.commit(mutationTypes.REMOVE_CURRENT_USER);
      store.commit(mutationTypes.REMOVE_AUTH_TOKEN);
      store.dispatch("addAlert", {
        ...alertTypes.danger,
        alert: "please login",
      });
      router.push("/");
    }

    if (e.response.status === 400) {
      store.dispatch("addAlert", {
        ...alertTypes.danger,
        alert: e.response.data.message,
      });
    }
    //   router.push("/")
    // store.disptach("addAlert", { ...alertTypes.danger, alert: "please login" });
  }
};

export default errorHandler;
