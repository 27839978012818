import types from "@/utils/mutation-types";

const uiStore = {
  state: () => ({
    sidebarCollapsed: false,
  }),
  mutations: {
    [types.SET_SIDEBAR_COLLAPSE_STATE](state, status) {
      state.sidebarCollapsed = status;
    },
  },
  actions: {},
  getters: {
    sidebarCollapsed(state) {
      return state.sidebarCollapsed;
    },
  },
};

export default uiStore;
