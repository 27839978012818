<template>
  <slot></slot>
</template>

<script>
export default {
  name: "LoginLayout",
};
</script>

<style lang="scss" scoped>
</style>