import types from "@/utils/mutation-types";
import axios from "@/utils/axios";
import errorHandler from "@/utils/error";
import alertTypes from "@/utils/alert-types";
import { handleError } from "@vue/runtime-dom";

const vendorStore = {
  state: () => ({
    vendors: [],
    vendorsSearchValue: "",
    totalVendors: 0,
    page: 1,
    totalPages: 0,
  }),
  mutations: {
    [types.SET_VENDORS](state, vendors) {
      state.vendors = vendors;
    },
    [types.ADD_VENDOR_TO_LIST](state, vendor) {
      state.vendors.push(vendor);
    },
    [types.UPDATE_VENDOR_IN_LIST](state, vendor) {
      state.vendors = state.vendors.map((vendorObj) => {
        if (vendorObj._id === vendor._id) return vendor;
        else return vendorObj;
      });
    },
    [types.REMOVE_VENDOR_FROM_LIST](state, id) {
      state.vendors = state.vendors.filter((vendor) => vendor._id != id);
    },
    [types.SET_VENDORS_SEARCH](state, data) {
      state.vendorsSearchValue = data;
    },
    [types.SET_VENDORS_PAGE](state, data) {
      state.page = data;
    },
    [types.SET_VENDORS_TOTAL_PAGES](state, data) {
      state.totalPages = data;
    },
    [types.SET_TOTAL_VENDORS](state, data) {
      state.totalVendors = data;
    },
  },
  actions: {
    async getVendorContacts({ commit, getters }, options = {}) {
      try {
        let uri = `${process.env.VUE_APP_API_HOST}/api/v1/vendors?page=${getters.vendorsPage}`;

        if (getters.vendorsSearchValue) {
          uri = `${uri}&search=${getters.vendorsSearchValue}`;
        }

        if (options.all === true) {
          uri = `${uri}&getAll=true`;
        }

        const res = await axios.get(uri, {
          headers: {
            authorization: getters.bearerToken,
          },
        });

        if (res.status === 200) {
          commit(types.SET_VENDORS, res.data.vendors);
          commit(types.SET_VENDORS_TOTAL_PAGES, res.data.totalPages);
          commit(types.SET_TOTAL_VENDORS, res.data.totalVendors);
          commit(types.SET_VENDORS_PAGE, res.data.page);
        }
      } catch (e) {
        // Handle Error
        console.log(e);
        errorHandler(e);
      }
    },

    async addVendorContact({ commit, getters, dispatch }, vendor) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/vendors`,
          vendor,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status == 200) {
          commit(types.ADD_VENDOR_TO_LIST, res.data.vendor);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "vendor added",
          });
        }
      } catch (e) {
        console.log(e);
        errorHandler(e);
      }
    },

    async editVendorContact({ getters, commit, dispatch }, vendor) {
      try {
        const res = await axios.patch(
          `${process.env.VUE_APP_API_HOST}/api/v1/vendors/${vendor._id}`,
          vendor,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status == 200) {
          commit(types.UPDATE_VENDOR_IN_LIST, res.data.vendor);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "vendor saved",
          });
        }
      } catch (e) {
        console.log(e);
        errorHandler(e);
      }
    },

    async deleteVendorContact({ getters, commit, dispatch }, id) {
      try {
        const res = await axios.delete(
          `${process.env.VUE_APP_API_HOST}/api/v1/vendors/${id}`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status == 200) {
          commit(types.REMOVE_VENDOR_FROM_LIST, res.data.vendorId);
          dispatch("addAlert", {
            ...alertTypes.warning,
            alert: "vendor deleted!",
          });
        }
      } catch (e) {
        console.log(e);
        handleError(e);
      }
    },
  },
  getters: {
    vendorContacts(state) {
      return state.vendors;
    },
    vendorsSearchValue(state) {
      return state.vendorsSearchValue;
    },
    totalVendors(state) {
      return state.totalVendors;
    },
    vendorsPage(state) {
      return state.page;
    },
    vendorsTotalPages(state) {
      return state.totalPages;
    },
  },
};

export default vendorStore;
