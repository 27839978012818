import { createStore } from "vuex";

import auth from "./modules/auth";
import alerts from "./modules/alert";
import inquiries from "./modules/inquiry";
import categories from "./modules/categories";
import branches from "./modules/branches";
import customers from "./modules/customers";
import vendors from "./modules/vendors";
import users from "./modules/users";
import ui from "./modules/ui";
import upload from "./modules/upload";
import customerCompanies from "./modules/customer-companies";
import documentBrands from "./modules/documentbrands";
import catalogues from "./modules/catalogues";
import manuals from "./modules/manuals";
import manualfolders from "./modules/manualfolders";
import supporttickets from "./modules/supporttickets";
import trendingslides from "./modules/trendingslides";

export default createStore({
  modules: {
    auth,
    alerts,
    inquiries,
    categories,
    branches,
    customers,
    vendors,
    users,
    ui,
    upload,
    customerCompanies,
    documentBrands,
    catalogues,
    manuals,
    manualfolders,
    supporttickets,
    trendingslides,
  },
});
