<template>
  <Alerts></Alerts>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import Alerts from "@/components/base/Alerts";
export default {
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  components: { Alerts },
};
</script>


<style lang="scss">
.router-link-active li,
.router-link-active {
  background-color: rgb(75, 85, 99);
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.bg-primary {
  background: #cfb47e;
}
</style>
