import types from "@/utils/mutation-types";
import errorHandler from "@/utils/error";
import alertTypes from "@/utils/alert-types";
import axios from "@/utils/axios";
import router from "@/router";

const supporTicketStore = {
  state: () => ({
    tickets: [
      /**
       * {
       * ticketId: 1234,
       * subject: This is the subject of the ticket
       * category: The category of the ticket
       * status: The status of the ticket
       * date: 10-10-2021
       * messages: [
       *    {
       *       date: 10-10-2021
       *       adminReply: false,
       *       message: test messsage,
       *       sender: Me,
       *       attachments: [attachment.jpg]
       *    }
       * ]
       * }
       */
    ],
  }),
  mutations: {
    [types.SET_SUPPORT_TICKETS](state, tickets) {
      state.tickets = tickets;
    },
    [types.ADD_SUPPORT_TICKET](state, ticket) {
      state.tickets.unshift(ticket);
    },
    [types.UPDATE_SUPPORT_TICKET](state, ticket) {
      state.tickets = state.tickets.map((t) => {
        if (t._id === ticket._id) return ticket;
        else return t;
      });
    },
  },
  actions: {
    //   Get Tickets for user
    async getSupportTickets({ commit, getters }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_HOST}/api/v1/support`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.SET_SUPPORT_TICKETS, res.data.tickets);
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    // Create a ticket
    async addSupportTicket({ dispatch, getters }, data) {
      try {
        const fd = new FormData();

        fd.append("message", data.message);
        fd.append("category", data.category);
        fd.append("subject", data.subject);

        if (data.files) {
          data.files.forEach((file) => fd.append("SupportAttachments", file));
        }

        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/support`,
          fd,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 201) {
          router.push("/support");
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "ticket added",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    // Reply to ticket
    async sendTicketReply({ getters, commit, dispatch }, data) {
      try {
        const fd = new FormData();

        fd.append("message", data.message);

        if (data.files.length) {
          for (const file of data.files) {
            fd.append("SupportAttachments", file);
          }
        }

        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/support/${data.id}`,
          fd,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.UPDATE_SUPPORT_TICKET, res.data.ticket);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "reply sent!",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    // close ticket
    async closeTicket({ commit, getters, dispatch }, id) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/support/${id}/close`,
          {},
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );
        if (res.status === 200) {
          commit(types.UPDATE_SUPPORT_TICKET, res.data.ticket);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "ticket closed!",
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    // Mark ticket as read
    async readTicketMessages({ commit, getters }, id) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/support/${id}/read`,
          {},
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );
        if (res.status === 200) {
          commit(types.UPDATE_SUPPORT_TICKET, res.data.ticket);
        }
      } catch (e) {
        errorHandler(e);
      }
    },
  },
  getters: {
    supportTickets(state) {
      return state.tickets.map((ticket) => {
        if (ticket.messages && ticket.messages.length) {
          ticket.messages = ticket.messages.reverse();
        }
        return ticket;
      });
    },
  },
};

export default supporTicketStore;
